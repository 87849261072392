declare var window: any;

function handleOTPInputs(){
	let otps = document.querySelectorAll('.group-otp');
	let re = /[0-9]/;

	otps.forEach(function(otp){
		if (!otp.classList.contains('i-otp-completed')){

			let inputMain = <HTMLInputElement> otp.querySelector('input');
			let maxLength = parseInt(inputMain?.getAttribute('maxlength'));
	
			let customInputs = document.createElement('div');
			customInputs.classList.add('custom');
	
			otp.appendChild(customInputs);
	
			var checkedPaste = false;
	
			var timeoutKeyup:any;
	
			for(let i = 0; i < maxLength; i++){
				let input = document.createElement('input');
				input.type = "number";
				input.setAttribute('maxlength', `1`);
				input.setAttribute('min', "0");
				input.setAttribute('max', "9");
	
				// input.addEventListener('keyup', handleKeyup);
	
				input.addEventListener('keyup', handleOTP)
	
				input.addEventListener('paste', function(e){
					console.log('paste');
	
					checkedPaste = true;
	
					const data = e.clipboardData.getData("text");
					const regex = /^[0-9]{6}$/;
					const value = data.split("");
	
					if (regex.test(data)){
						if (value.length === customInputs.children.length){
							let inputs = customInputs.children;
							for (let idxInput = 0; idxInput < inputs.length; idxInput ++){
								let inputItem = <HTMLInputElement> inputs[idxInput];
								inputItem.value = value[idxInput];
								
								inputItem.focus();
							}
		
							inputMain.value = `${data}`;
		
						}
					}
				})
	
				customInputs.appendChild(input);
			}
	
			function handleOTP(e:any){
				const input = e.target;
				let value = input.value;
	
				input.value = "";
				input.value = value ? value[0] : "";
	
				
				let fieldIndex = input.dataset.index;
	
				if (value.length > 0 && fieldIndex < customInputs.children.length){
					input.nextElementSibling.focus();
				}
				else if(e.key === "Backspace" && fieldIndex > 1){
					input.previousElementSibling.focus();
				}
	
				let inputs = customInputs.children;
				inputMain.value = "";
	
				for (let idxInput = 0; idxInput < inputs.length; idxInput ++){
					let input = <HTMLInputElement> inputs[idxInput];
					inputMain.value += input.value !== "" ? input.value : " ";
				}
			}
	
			function handleKeyup(e:any){
				let inputTarget = e.target;
	
				if (e.key === "Backspace"){
					if (inputTarget.previousElementSibling){
						inputTarget.previousElementSibling.focus();
					}
				}
				else if (inputTarget.nextElementSibling){
					inputTarget.nextElementSibling.focus();
				}
	
	
			}
	
	
			let inputs = otp.querySelectorAll('input');
	
			inputs.forEach((item, idx) =>{
				item.dataset.index = `${idx}`;
				item.addEventListener("paste", handleOTP_paste);
				item.addEventListener("keyup", handleOTP_keyup);
			})
	
			function handleOTP_paste(e:any){
				const data = e.clipboardData.getData("text");
				const value = data.split("");
				if (value.length === inputs.length){
					inputs.forEach((input, index) => {
						return input.value = value[index];
					})
				}
			}
			function handleOTP_keyup(e:any){
				const input = e.target;
				let value = input.value;
				input.value = "";
				input.value = value ? value[0] : "";
	
				let fieldIndex = input.dataset.index;
				if (value.length > 0 && parseInt(fieldIndex) < input.length - 1){
					input.nextElementSibling.focus();
				}
			
				if (e.key === "Backspace" && fieldIndex > 0){
				}
			}
	
			otp.classList.add('i-otp-completed');
		}
	})
}


export const initForm = function () {
	handleOTPInputs();
	changeInputInfor();
};


function changeInputInfor(){
	let groupInpus = document.querySelectorAll('.group-input');
	groupInpus.forEach(function(grInp){
		let input = grInp.querySelector('input');
		let aChange = grInp.querySelector('.change');
		
		if (aChange){
			if (!grInp.classList.contains('i-change-completed')){	
				if (aChange && !aChange.classList.contains('changePwd')){
					aChange.addEventListener('click', function(e){
						e.preventDefault();
						input.removeAttribute('readonly');
						aChange.remove();
						input.focus();
		
						let value = input.value;
		
						input.value = "";
						input.value =  value;
					})
				}
		
				grInp.classList.add('i-change-completed');
			}
		}
	})
}

// function initTagInput(){
//     var TagsInput: any;

// 	// Plugin Constructor
// 	TagsInput = function (opts: any) {
// 		this.options = Object.assign(TagsInput.defaults, opts);
// 		this.init();
// 	};

// 	// Initialize the plugin
// 	TagsInput.prototype.init = function (opts: any) {
// 		this.options = opts ? Object.assign(this.options, opts) : this.options;

// 		if (this.initialized) this.destroy();

// 		if (
// 			!(this.orignal_input = document.getElementById(
// 				this.options.selector,
// 			))
// 		) {
// 			console.error(
// 				"tags-input couldn't find an element with the specified ID"
// 			);
// 			return this;
// 		}

// 		this.arr = [];
// 		this.wrapper = document.createElement("div");
// 		this.input = document.createElement("input");
// 		init(this);
// 		initEvents(this);

// 		this.initialized = true;
// 		return this;
// 	};

// 	// Add Tags
// 	TagsInput.prototype.addTag = function (string: any) {
// 		if (this.anyErrors(string)) return;

// 		this.arr.push(string);
// 		var tagInput = this;

// 		var tag = document.createElement("span");
// 		tag.className = this.options.tagClass;
// 		tag.innerText = string;

// 		var closeIcon = document.createElement("a");
// 		closeIcon.innerHTML = "&times;";

// 		// delete the tag when icon is clicked
// 		closeIcon.addEventListener("click", function (e) {
// 			e.preventDefault();
// 			var tag = this.parentNode;

// 			for (var i = 0; i < tagInput.wrapper.childNodes.length; i++) {
// 				if (tagInput.wrapper.childNodes[i] == tag)
// 					tagInput.deleteTag(tag, i);
// 			}
// 		});

// 		tag.appendChild(closeIcon);
// 		this.wrapper.insertBefore(tag, this.input);
// 		this.orignal_input.value = this.arr.join(",");

// 		return this;
// 	};

// 	// Delete Tags
// 	TagsInput.prototype.deleteTag = function (tag: any, i: any) {
// 		tag.remove();
// 		this.arr.splice(i, 1);
// 		this.orignal_input.value = this.arr.join(",");
// 		return this;
// 	};

// 	// Make sure input string have no error with the plugin
// 	TagsInput.prototype.anyErrors = function (string: any) {
// 		if (this.options.max != null && this.arr.length >= this.options.max) {
// 			console.log("max tags limit reached");
// 			return true;
// 		}

// 		if (!this.options.duplicate && this.arr.indexOf(string) != -1) {
// 			console.log('duplicate found " ' + string + ' " ');
// 			return true;
// 		}

// 		return false;
// 	};

// 	// Add tags programmatically
// 	TagsInput.prototype.addData = function (array: any) {
// 		var plugin = this;

// 		array.forEach(function (string: any) {
// 			plugin.addTag(string);
// 		});
// 		return this;
// 	};

// 	// Get the Input String
// 	TagsInput.prototype.getInputString = function () {
// 		return this.arr.join(",");
// 	};

// 	// destroy the plugin
// 	TagsInput.prototype.destroy = function () {
// 		this.orignal_input.removeAttribute("hidden");

// 		delete this.orignal_input;
// 		var self = this;

// 		Object.keys(this).forEach(function (key) {
// 			if (self[key] instanceof HTMLElement) self[key].remove();

// 			if (key != "options") delete self[key];
// 		});

// 		this.initialized = false;
// 	};

// 	// Private function to initialize the tag input plugin
// 	function init(tags: any) {
// 		tags.wrapper.append(tags.input);
// 		tags.wrapper.classList.add(tags.options.wrapperClass);
// 		tags.orignal_input.setAttribute("hidden", "true");
// 		tags.orignal_input.parentNode.insertBefore(
// 			tags.wrapper,
// 			tags.orignal_input,
// 		);
// 	}

// 	// initialize the Events
// 	function initEvents(tags: any) {
// 		tags.wrapper.addEventListener("click", function () {
// 			tags.input.focus();
// 		});

// 		tags.input.addEventListener("keydown", function (e: any) {
// 			var str = tags.input.value.trim();

// 			if (!!~[9, 13, 188].indexOf(e.keyCode)) {
// 				e.preventDefault();
// 				tags.input.value = "";
// 				if (str != "") tags.addTag(str);
// 			}
// 		});
// 	}

// 	// Set All the Default Values
// 	TagsInput.defaults = {
// 		selector: "",
// 		wrapperClass: "tags-wrapper",
// 		tagClass: "tag",
// 		max: null,
// 		duplicate: false,
// 	};

// 	window.TagsInput = TagsInput;
// }