declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;
declare var pagination:any;

export const account = function () {
    if (document.getElementById('accountPage')){
    }
}
