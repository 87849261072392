declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var pagination: any;

const bgOdd = "#EBE3DA";
const bgEven = "#FFF";

export const promotion = function () {
    if (document.getElementById('promotionPage')) {
        initPage();
    }
    else if (document.getElementById('promotionDetailPage')) {

    }
}

function initPage() {
    let arrItem: any[] = [];
    let page = 3;

    let bc = <HTMLElement>document.querySelector('.breadcrumbs');

    let eTitle = <HTMLElement>document.querySelector('.promotion-1');
    let ePagination = <HTMLElement>document.querySelector('.promotion-2 .pagination');
    let bgCurrent = bgOdd;

    let pagePromotion = new pagination({
        selector: ".promotion-2 .pagination",
        items: ".promotion-2 .promotion-ls .item",
        page: page,
        scroll: {
            id: "idPromotion"
        },
        callback: function (item: HTMLElement, idx: number, pageActive: number, lens: number) {
            let iActive = (idx % page) + 1;
            let pos = page * (pageActive - 1) + iActive;

            if (iActive !== 1) {
                item.style.borderTop = "1px solid #000";
            } else {
                item.style.borderTop = "none";
            }

            bgCurrent = bgCurrent == bgOdd ? bgEven : bgOdd;

            if (iActive === 1) {
                bc.style.setProperty('--bgSection', bgCurrent);
                eTitle.style.setProperty('--bgSection', bgCurrent);
            }

            item.style.setProperty('--bgSection', bgCurrent);

            if (iActive % 2 === 0) {
                item.classList.add('reverse');
            }
            else {
                item.classList.remove('reverse');
            }

            arrItem.push(item);

            if (iActive === page || lens === pos) {
                ePagination.style.setProperty('--bgSection', bgCurrent);
                arrItem = [];
            }
            // console.log(Math.ceil(lens / page));
            if (Math.ceil(lens / page) == 1) {
                ePagination.style.display = "none";
            }
        }
    })
}