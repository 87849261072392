declare var Fancybox:any;

function init(){  
  let ls = document.querySelectorAll('a[data-fancybox]');
  let arrayFancy:any[] = [];
  ls.forEach(function(item){
    if (!arrayFancy.includes(item.getAttribute('data-fancybox'))){
      arrayFancy.push(item.getAttribute('data-fancybox'));
    }
  })

  arrayFancy.forEach(function(item){
    Fancybox.bind(`[data-fancybox="${item}"]`, {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },
  
        Image: {
          zoom: true,
        },
    });
  })

    // Fancybox.bind('[data-fancybox="gallery-2"]', {
    //     Thumbs: {
    //       Carousel: {
    //         fill: false,
    //         center: true,
    //       },
    //     },

    //     Image: {
    //       zoom: true,
    //     },
    // });
    // Fancybox.bind('[data-fancybox="gallery-3"]', {
    //     Thumbs: {
    //       Carousel: {
    //         fill: false,
    //         center: true,
    //       },
    //     },

    //     Image: {
    //       zoom: true,
    //     },
    // });
    // Fancybox.bind('[data-fancybox="gallery-video"]', {
    //     Thumbs: {
    //       Carousel: {
    //         fill: false,
    //         center: true,
    //       },
    //     },

    //     // Image: {
    //     //   zoom: true,
    //     // },
    // });
}

export const fancyboxLib = function(){
    init();
}