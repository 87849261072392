declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;

export const index = function () {
    if (document.getElementById('indexPage')){
        sw1();
        // slick4();
        
        handle4();
        handle5();

        mouse6();

        sw7();
    }

    // $(".tag-select").val(null).trigger('change');
    
    // if (document.getElementById('formFooter')){
    //     $("#formFooter .tag-select").select2({
    //       tokenSeparators: [',', ' '],
    //       placeholder: $('.tag-select').attr('placeholder'),
    //       allowClear: true
    //     });
    // }
};


function mouse6(){
    let block = document.querySelector('.idx-6');
    let cursor = <HTMLElement>block?.querySelector('.section-cursor');

    var timeout:any;

    block?.addEventListener('mousemove', function(evt:any){
        let target = <HTMLElement>evt.target;
        let top =block.getBoundingClientRect().top;
        cursor.style.left = evt.x + "px";
        cursor.style.top = evt.y - top + "px";

        let checked = false;

        target = target.parentElement;
        
        do{
            if (target.classList.contains('item-more')){
                checked = true;
                break;
            }
            target = target.parentElement;
        }while(target);
        
        if (checked){
            cursor.style.display = "";
        }
        else{
            cursor.style.display = "block";
        }

        clearTimeout(timeout);
        timeout = setTimeout(function(){
            mouseStopped();
        }, 2000)
    })
    
    function mouseStopped(){
        cursor.style.display = "none";
    }
    block?.addEventListener('mouseleave', function(evt:any){
        cursor.style.display = "";
    })
}
function handle4(){
    let items = document.querySelectorAll('.idx-4 .item');
    items.forEach(function(item){
        let link = <HTMLElement>item.querySelector('.item-name a');
        let img = <HTMLElement>item.querySelector('.item-img');
        
        img?.addEventListener('click', function(e){
            link?.click();
        })
    })
}
function handle5(){
    let items = document.querySelectorAll('.idx-5 .item');
    items.forEach(function(item){
        let link = <HTMLElement>item.querySelector('.item-more a');
        item.addEventListener('click', function(e){
            link?.click();
        })

        link?.addEventListener('click', function(e){
            e.stopPropagation();
        })
    })
}

function sw7(){
    var sw = new Swiper(".idx-7 .swiper", {
        slidesPerView: "auto",
        spaceBetween: 10,
        initialSlide: 1,
        slidesPerGroup: 1,

        loop: true,

        speed: 1200,
        autoplay:{
            delay: 2000,
        },

        pagination: {
          el: ".idx-7 .swiper-pagination",
          clickable: true,
          dynamicBullets: true,
        },

        breakpoints: {
            768: {
                slidesPerGroup: 2,
                spaceBetween: 18,
            }
        }
    })
}

function slick4(){
    $('.idx-4 .idx-4-wrapper .row').slick({
        rows: 2,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        dots: true,
        dotClass: "slick-dots",

        autoplay: true,
        // speed: 800,
        autoplaySpeed: 3000,

        // cssEase: 'linear',

        adaptiveHeight: true,
        pauseOnHover: false,
        pauseOnFocus: true,
        pauseOnDotsHover: false,
        swipe: true,
        touchMove: true,
    })
}

function sw1(){
    var sw = new Swiper(".idx-1 .swiper", {
        loop: true,
        effect: 'fade',

        speed: 1200,

        autoplay: {
            delay: 4000
        },

        pagination: {
            el: ".idx-1 .swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
    })
}
