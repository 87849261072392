declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;



export const banner = function (){
    sw1();
}

function sw1(){
    var sw = new Swiper(".sub-banner .swiper", {
        loop: true,
        effect: 'fade',

        speed: 1200,

        autoplay: {
            delay: 4000
        },

        pagination: {
            el: ".sub-banner .swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
    })
}