declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;
declare var pagination:any;

export const news = function () {
    if (document.getElementById('newsPage')){
        initPage();
    }
}


function initPage(){
    let pageNews1 = new pagination({
        selector: ".news-1 .pagination",
        items: ".news-1 .news-ls .item",
        page: 6,
        scroll: {
            id: "idNews"
        }
    })
}