declare var window :any;

export const header = function(){
    const tagHeader = document.querySelector('header');
    const tagBody = document.querySelector('body');

    if (window.scrollY > 0){
        tagHeader.classList.add('scroll');
    }
    else{
        tagHeader.classList.remove('scroll');
    }
    let timeout:any;
    window.addEventListener('scroll', function(e:any){
        this.clearTimeout(timeout);
        timeout = this.setTimeout(function(){
            if (window.scrollY > 0){
                tagHeader.classList.add('scroll');
            }
            else{
                tagHeader.classList.remove('scroll');
            }
        }, 60)
    })

    let mbMenu = document.querySelector('.header-mb-menu');
    let primary = document.querySelector('.header-mb__primary');
    primary?.addEventListener('click', function(e){
        e.preventDefault;
        mbMenu.classList.add('show');
        tagBody.classList.add('hideScroll');
    })

    mbMenu?.addEventListener('click', function(e){
        let target = <HTMLElement>e.target;
        if (target.classList.contains('header-mb-menu')){
            target.classList.remove('show');
            tagBody.classList.remove('hideScroll');
        }
    })
    
    let close = mbMenu?.querySelector('.close');
    close?.addEventListener('click', function(e){
        e.preventDefault();
        mbMenu?.classList.remove('show');
        tagBody.classList.remove('hideScroll');
    })


    let mbHeaderBottom = document.querySelector('.header-mb-bottom');
    if (mbHeaderBottom){
        let items = mbHeaderBottom.querySelectorAll('ul.bottom-nav>li>a');
        items.forEach(function(item:HTMLElement){
            let parent = item.parentElement;
            let navsub = parent.querySelector('.navsub');
            if (navsub){
                item.addEventListener('click', function(e){
                    e.preventDefault();
                    
                    navsub.classList.toggle('showed');
                    if (navsub.classList.contains('showed')){
                        parent.classList.add('active');
                    }
                    else{
                        parent.classList.remove('active');
                    }
                })
            }
        })
    }

    let subaccount = document.querySelector('.header__accounts>a');
    if (subaccount){
        let parent = subaccount.parentElement;
        let navsub = parent.querySelector('.navsub');

        if (navsub){
            subaccount.addEventListener('click', handleClickSubAccount);

            function handleClickSubAccount(e:any){
                if (subaccount.getAttribute('data-popup')){
                    subaccount.removeEventListener('click', handleClickSubAccount);
                    return;
                }
                
                e.preventDefault();
                navsub.classList.toggle('showed');
                if (navsub.classList.contains('showed')){
                    parent.classList.add('active');
                }
                else{
                    parent.classList.remove('active');
                }
            }

            // document.addEventListener('click', function(e:any){
            //     let target = e.target;
            //     if (target.classList.contains('header__accounts')){
            //         return;
            //     }
            //     target = target.parentElement;
                
            //     do{
            //         if (target.classList.contains('header__accounts')){
            //             return;
            //         }
            //         target = target.parentElement;
            //     }while(target.parentElement);

            //     navsub.classList.remove('showed');
            //     parent.classList.remove('active');
            // })

            window.targetHandle(document, function(target:HTMLElement){
                if (target.classList.contains('header__accounts')){
                    return true;
                }
                return false;
            }, function(){
                navsub.classList.remove('showed');
                parent.classList.remove('active');
            })
        }
    }
}