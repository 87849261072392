declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;
declare var pagination:any;


export const libs = function () {
    if (document.getElementById('librariesPage')){
        // handle1();
        initPage();

    }
}

function handle1(){
    // let ls = document.querySelectorAll('.libs-1-ls .item');
    // ls.forEach(function(item:HTMLElement){
    //     initSw(item);
    // })


    // ls.forEach(function(item:HTMLElement){
    //     item.style.display = "none";
    // })
    // let option = {
    //     classList: document.querySelector('.libs-1-ls'),
    //     quanity: 1
    // }
    // let page = new _Pagination(option, function(child:HTMLElement){
    //     initSw(child);
    // })
}



function initPage(){
    let swArray:any[] = [];
    let page = 3;

    let pageChecked1 = new pagination({
        selector: ".libs-1 .pagination",
        items: ".libs-1 .libs-1-ls .item",
        page: page,
        scroll: {
            id: "idLibraries"
        },
        callback: function(item:HTMLElement, index:number){
            if (swArray.length >= page){
                swArray.forEach(function(sw){
                    sw.destroy();
                })

                swArray = [];
            }

            swArray.push(initSw(item));
        }
    })
}

function initSw(eSwiper:HTMLElement){
    var sw = new Swiper(eSwiper.querySelector('.swiper'), {
        slidesPerView: "auto",
        spaceBetween: 10,
        initialSlide: 1,
        slidesPerGroup: 1,

        speed: 1200,

        autoplay:{
            delay: 3200,
            disableOnInteraction: false
        },

        pagination: {
          el: eSwiper.querySelector('.swiper-pagination'),
          clickable: true,
          dynamicBullets: true,
        },

        breakpoints: {
            768: {
                slidesPerGroup: 2,
                spaceBetween: 18,
            }
        }
    })

    return sw;
}