// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started
declare var window:any;

declare var Swiper : any;
declare var $ : any;
declare var AOS : any;
declare var gsap : any;
declare var Fancybox:any;
declare var Carousel:any;
declare var Panzoom:any;
declare var THREE: any;

declare var ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);

// Fancybox.defaults.ScrollLock = false;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
import {_base} from "./libraries/_base";
import {_imgClass} from "./libraries/_imgClass";
import {fancyboxLib} from "./libraries/fancybox-lib";
import {gsapScroll} from './libraries/gsapScroll';
import {_dateTime} from './libraries/_dateTimePicker';
import {initPage} from "./libraries/pagination";
import {initForm} from "./libraries/_initForm";

_base();

function copyMaker(){
  let copyElements = document.querySelectorAll('.copyMaker');
  copyElements.forEach(function(copyElement){
    if (!copyElement.classList.contains('i-cm-completed')){
      window.copyMaker(copyElement, function(status:string,text:string){
          window.addToastMsg(status, text);
      })
      copyElement.classList.add('i-cm-completed');
    }
  })
}

import {boxProcedure} from "./components/_box-procedure";

// Layout
import {header} from "./layout/header";
import {banner} from "./layout/banner";
import {breadcrumbs} from "./layout/breadcrumbs";
import {popup} from "./layout/popup";

// Page
import {template} from "./pages/template";

import {index} from "./pages/index";
import {about} from "./pages/about";
import {services} from "./pages/services";
import {promotion} from "./pages/promotion";
import {news} from "./pages/news";
import {checked} from "./pages/checked";
import {libs} from "./pages/libraries";
import {account} from "./pages/account";


// lib
_imgClass();

initPage();
fancyboxLib();
gsapScroll();
copyMaker();

setInterval(() => {
  window.dispatchEvent(
    new CustomEvent('_tagselect', {
      detail: `Init tag select 2`
    })
  )

  window.dispatchEvent(
    new CustomEvent('_imgClass', {
      detail: `Init tag svg to img`
    })
  )
  window.dispatchEvent(
    new CustomEvent('_dateTime', {
      detail: `Init datetimepicker`
    })
  )
  window.dispatchEvent(
    new CustomEvent('_initForm', {
      detail: `Init Form event`
    })
  )
}, 2000)


//////////////////////////////////////////////////////////////
window.addEventListener('DOMContentLoaded', function() {  
  // components
  boxProcedure();

  // layout
  header();
  banner();
  breadcrumbs();
  popup();
  
  // ----- Page in here ----- //
  template();

  index();
  about();
  services();
  promotion();
  news();
  checked();
  libs();

  account();

  window.addEventListener('_imgClass', function(){
  })
  
  window.addEventListener('_dateTime', function(){
    _dateTime();
  })
  window.addEventListener('_initForm', function(){
    initForm();
  })

  window.addEventListener('_tagselect', function(){
    let tags = document.querySelectorAll('.tag-select');
    tags.forEach(function(item){
      if (!item.classList.contains('select2-hidden-accessible')){
        $(item).select2({
          tokenSeparators: [',', ' '],
          placeholder: $('.tag-select').attr('placeholder'),
          allowClear: true
        });  
      }
    })
    let branches = document.querySelectorAll('.select-branch');
    branches.forEach(function(branch){
      if (!branch.classList.contains('select2-hidden-accessible')){
        $(branch).select2();
      }
    })
  })

  // test
  // setTimeout(function(){
  //   let gr = document.querySelector('.group-input');
  //   gr.innerHTML += `
  //   <input class="formTime" type="text" id="formTime2" placeholder="Ngày và giờ" autocomplete="off">
  //   `
  // }, 10000)
})


/////////////////////WEB FULLPAGE///////////////////////////
// import loadingAni from "./initAnimation/loading";

// const fullpageLoad = function(){
//   let loading = document.querySelector('#loading-container');
//   loadingAni.aniIn();
//   // import animateFullpage from "./components/animateFullpage";
//   // animateFullpage.default();
  
//   window.addEventListener('load', function() {
//     // swiper_nav();
//     allJs();
//     setTimeout(function(){
//       loading.classList.add('hiddenLoading');
//       setTimeout(function(){
//         loading.parentElement.removeChild(loading);

//         // let utiSubMap = document.getElementById('UtilitiesSubPage');
//         // if (utiSubMap){
//         //   let map = utiSubMap.querySelector('.map');
//         //   map.classList.add('animateActive');
//         // }
//       }, 700)
//       common();
//     }, loadingAni.delay)
//   })
// }

// fullpageLoad();

/////////////////////WEB NORMAL///////////////////////////

