declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;
declare var pagination:any;

export const checked = function () {
    if (document.getElementById('checkedPage')){
        initPage();
    }
}

function initPage(){
    let page = 6;

    let pageChecked1 = new pagination({
        selector: ".checked-1 .pagination",
        items: ".checked-1 .checked-1-ls .item",
        page: page,
        scroll: {
            id: "idChecked"
        },

    })
}