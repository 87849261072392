declare var window:any;
declare var Swiper : any;
declare var $ : any;
declare var gsap : any;
declare var ScrollTrigger: any;

export const services = function(){
    if (document.getElementById('servicesPage')){
        handle1();

        // $(".form-footer .tag-select").select2({
        //     tokenSeparators: [',', ' '],
        //     placeholder: $('.tag-select').attr('placeholder'),
        //     allowClear: true
        // });
    }
    else if (document.getElementById('servicesDetailPage')){
        // copyLinkDetail();
    }
}

const hItem = 5;


function swiperCreative1(eSwiper:HTMLElement){
    var swiper = new Swiper(eSwiper, {
        grabCursor: true,
        effect: "creative",
        creativeEffect: {
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        },

        autoplay: false,
    });

    new Swiper('.svs-section .box-service-ls')
    
    return swiper;
}

function handle1(){
    let lsBoxService = document.querySelectorAll('.box-service');
    lsBoxService.forEach(function(box){
        const thumb = <HTMLElement>box.querySelector('.box-service__thumbnails');
        const lsItem = box?.querySelectorAll('.box-service-ls .item');
        const eUp = <HTMLElement> box?.querySelector('.up');
        const eDown = <HTMLElement> box?.querySelector('.down');

        if (!thumb || lsItem.length === 0){
            return;
        }
    
        let idxCurrent = 0;
        let checkIsActived = false;
        lsItem.forEach(function(temp, idx){
            if (temp.classList.contains('is-actived')){
                idxCurrent = idx;
                checkIsActived = true;
            }
        });

        sliderItem(box, lsItem, idxCurrent);

        if (!checkIsActived && lsItem.length > 0){
            lsItem[0].classList.add('is-actived');
        }

        const swInit = swiperCreative1(thumb);
        swInit.slideTo(idxCurrent);

        setInterval(function(){
            swInit.autoplay.start();
        }, 3000)

        checkArrowEffect(lsItem, eUp, eDown);

        lsItem.forEach(function(item, idx){
            item.addEventListener('click', function(e){
                e.preventDefault();
    
                window.delHandle(lsItem, 'is-actived');

                item.classList.add('is-actived');
                idxCurrent = idx;

                swInit.slideTo(idx);

                checkArrowEffect(lsItem, eUp, eDown);

                sliderItem(box, lsItem, idxCurrent);
            })
        })
    
        eUp.addEventListener('click', function(e){
            e.preventDefault();

            handleUp(lsItem, idxCurrent);
            idxCurrent--;

            checkArrowEffect(lsItem, eUp, eDown);
            swInit.slidePrev();

            sliderItem(box, lsItem, idxCurrent);
        })

        eDown.addEventListener('click', function(e){
            e.preventDefault();

            handleDown(lsItem, idxCurrent);
            idxCurrent++;

            checkArrowEffect(lsItem, eUp, eDown);
            swInit.slideNext();

            sliderItem(box, lsItem, idxCurrent);
        })

        swInit.on('slideChange', function () {
            let activeIdx = swInit.activeIndex; // Lay phan tu index thay doi
            window.delHandle(lsItem, 'is-actived');
            lsItem[activeIdx].classList.add('is-actived');

            checkArrowEffect(lsItem, eUp, eDown);

            idxCurrent = activeIdx;
            sliderItem(box, lsItem, idxCurrent);
        });
    })

}

function handleUp(list:any, idxCurrent:any){
    list.forEach(function(item:HTMLElement, idx:number){
        if (idxCurrent > 0){
            if (idx == idxCurrent){
                item.classList.remove('is-actived');
            }
            else if (idx == idxCurrent - 1){
                item.classList.add('is-actived');

            }            
        }
    })
}

function handleDown(list:any, idxCurrent:any){
    let ilen = list.length - 1;

    list.forEach(function(item:HTMLElement, idx:number){
        if (idxCurrent < ilen){
            if (idx == idxCurrent){
                item.classList.remove('is-actived');
            }
            else if (idx == idxCurrent + 1){
                item.classList.add('is-actived');
            }            
        }
    })
}


function checkArrowEffect(list:any, up:HTMLElement, down:HTMLElement){
    let ilen = list.length - 1;
    list.forEach(function(item:HTMLElement, idx:number){
        if (item.classList.contains('is-actived')){
            if (idx === 0 && idx === ilen){
                up.classList.remove('is-animated');
                down.classList.remove('is-animated');
            }
            else if (idx === 0){
                up.classList.remove('is-animated');
                down.classList.add('is-animated');
            }
            else if (idx === ilen){
                up.classList.add('is-animated');
                down.classList.remove('is-animated');
            }
            else{
                up.classList.add('is-animated');
                down.classList.add('is-animated');
            }
        }
    })
}

function heightSlide(lsItem:any){
    let hCurrent = 0;
    lsItem.forEach(function(item:HTMLElement, idx:number){
        if (idx < hItem){
            hCurrent += item.clientHeight;
        }
    })

    return hCurrent;
}

function heightSlideMb(lsItem:any){
    let hCurrent = 0;
    lsItem.forEach(function(item:HTMLElement, idx:number){
        if (hCurrent < item.clientHeight){
            hCurrent = item.clientHeight;
        }
    })

    return hCurrent;
}

function spaceSlide(lsItem:any, iActive:number){
    let spaceCurrent = 0;

    let ican = 0;

    if (iActive === 0){
        return 0;
    }

    // slide 3
    for (let i = 0; i < iActive - 1; i++){
        if (i >= 2 && i <= lsItem.length - 4){
            spaceCurrent += lsItem[i].clientHeight;
        }
    }

    return spaceCurrent;
}

function spaceSlideMb(lsItem:any, iActive:number){
    let length = lsItem.length;

    // khi là phần tử đầu tiên
    if (iActive === 0){
        return 0;
    }
    
    let max = 0;
    for (let i = 0; i < length; i++){
        max += lsItem[i].clientWidth;
    }

    let obj_x = lsItem[iActive].clientWidth;
    let x = 0;
    let screenBox = document.querySelector('.box-service__slider').clientWidth;

    for (let i = 0; i < iActive; i++){
        x += lsItem[i].clientWidth;
    }
    
    // khi là phần tử cuối
    if (iActive === length - 1){
        return - x + screenBox - obj_x;
    }

    // x tổng các phần tử đằng sau
    let maxBehind = 0;
    for (let i = iActive + 1; i < length; i++){
        maxBehind += lsItem[i].clientWidth;
    }

    // nếu lớn hơn widthBox thì có thể ở giữa
    let condition = (screenBox / 2) + (obj_x / 2) + maxBehind;

    if (condition > screenBox){
        console.log('center');
        return - x + (screenBox / 2) - (obj_x / 2);
    }

    // không đủ kiều kiện
    return - max + screenBox;
}

function sliderItem(box:any, lsItem:any, iActive: number = 0){
    if (window.innerWidth >= 1080){
        let sliderLs = <HTMLElement>box.querySelector('.box-service__slider');
        if (sliderLs){
            sliderLs.style.height = ``;
            let hCurrent = heightSlide(lsItem);
            sliderLs.style.height = `${hCurrent}px`;
        
            let spaceCurrent = spaceSlide(lsItem, iActive);
            lsItem.forEach(function(item:HTMLElement, idx:number){
                item.style.transform = `translateY(-${spaceCurrent}px)`;
            })
        }
    }
    else{
        let sliderLs = <HTMLElement>box.querySelector('.box-service__slider');
        if (sliderLs){
            sliderLs.style.height = ``;
    
            let hCurrent = heightSlideMb(lsItem);
            sliderLs.style.height = `${hCurrent}px`;
    
            let spaceCurrent = spaceSlideMb(lsItem, iActive);
    
            lsItem.forEach(function(item:HTMLElement, idx:number){
                item.style.transform = `translateX(${spaceCurrent}px)`;
            })
        }
    }
}



// function copyLinkDetail(){
//     let textSuccess = 'Sao chép thành công';
//     let textError = 'Sao chép thất bại';
//     window.copyMaker('https://braney.vn', function(status: string){
//         if (status === 'success'){
//             console.log(textSuccess);
//         }else if (status === 'error'){
//             console.error(textError);
//         }else{
//             console.log('Not status copymaker!');
//         }
//     })
// }

