declare var window:any;
declare var Swiper : any;
declare var $ : any;
declare var gsap : any;
declare var ScrollTrigger: any;
declare var pagination:any;

export const template = function(){
    postItem();
}

function postItem(){
    let items = document.querySelectorAll('.tmp-post');
    items.forEach(function(item){
        let link = <HTMLElement>item.querySelector('.tmp-post-more a');
        item.addEventListener('click', function(e){
            link?.click();
        })

        link?.addEventListener('click', function(e){
            e.stopPropagation();
        })
    })
}