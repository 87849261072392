declare var gsap: any;
declare var ScrollTrigger: any;

gsap.registerPlugin(ScrollTrigger);

export const gsapScroll = function () {
    initHome()
}

function initHome(){
    // gsap.set(".idx-2 .brand", {
    //     rotation: 0,
    //     x: 0,
    //     scale: 0,
    //     opacity: 0
    // })
    // gsap.set(".idx-2 .slogan", {
    //     opacity: 0
    // })

    // gsap.to(".idx-2 .brand", {
    //     scrollTrigger: {
    //         trigger: ".idx-2 .brand",
    //         toggleActions: "play none none none",
    //         start: "top center"
    //     },
    //     opacity: 1,
    //     scale: 1,
    //     duration: 0.3
    // })
    // gsap.to(".idx-2 .slogan", {
    //     scrollTrigger: {
    //         trigger: ".idx-2 .slogan",
    //         toggleActions: "play none none none",
    //     },
    //     opacity: 1,
    //     duration: 2,
    //     className: "slogan animate__animated animate__flash"
    // })


    // gsap.set('idx-3 .image', {
        
    // })
}