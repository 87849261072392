declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;
declare var THREE: any;
declare var pagination:any;

export const about = function () {
    if (document.getElementById('aboutPage')){
        slick2();

        handle3();
    }
}

function slick2(){
    $('.ab-2 .wrapper-lst').slick({
        rows: 2,
        slidesToShow:3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        
        dots: true,
        dotClass: "slick-dots",

        autoplay: true,
        // speed: 800,
        autoplaySpeed: 3000,

        // cssEase: 'linear',

        adaptiveHeight: true,
        pauseOnHover: false,
        pauseOnFocus: true,
        pauseOnDotsHover: false,
        swipe: true,
        touchMove: true,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }  
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1
                }  
            }
        ]
    })
}


function handle3(){
    var lstQ = document.querySelectorAll(".ab-3 .lst-question .item");
    lstQ.forEach(function(item:HTMLElement){
        let head = item.querySelector('.head');
        head.addEventListener('click', function(e){
            e.preventDefault();

            if (item.classList.contains('is-actived')){
                item.classList.remove('is-actived');
            }else{
                window.delHandle(lstQ, 'is-actived');
                item.classList.add('is-actived');
            }
        })
    })

    let pageAb3 = new pagination({
        selector: ".ab-3 .pagination",
        items: ".ab-3 .lst-question .item",
        page: 5,
        scroll: {
            id: "idQuestion"
        }
    })
}

